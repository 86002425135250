import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as Survey from "survey-react";
import "survey-react/modern.css";

Survey.StylesManager.applyTheme("modern");

var surveyJSON = {"title":"Moofwd Survey","pages":[{"name":"page1","elements":[{"type":"text","name":"What is your name ?","isRequired":true},{"type":"radiogroup","name":"Do you have covid symptoms ?","isRequired":true,"requiredIf":"{What is your name ?} empty and {Do you have covid symptoms ?} empty and {Indicate your symptoms} empty","choices":[{"value":"item1","text":"Yes"},{"value":"item2","text":"No"},{"value":"item3","text":"Not sure"}]},{"type":"checkbox","name":"Indicate your symptoms","isRequired":true,"choices":[{"value":"item1","text":"Fever"},{"value":"item2","text":"Headache"},{"value":"item3","text":"Cough"}]},{"type":"dropdown","name":"question1","title":"Do you have covid symptoms ?","isRequired":true,"choices":[{"value":"item1","text":"Yes"},{"value":"item2","text":"No"},{"value":"item3","text":"Not sure"}]},{"type":"comment","name":"Write a comment"},{"type":"boolean","name":"question2","title":"Continue"}],"title":"Moofwd","description":"Satander Survey"}],"triggers":[{"type":"complete","expression":"{What is your name ?} notempty and {Do you have covid symptoms ?} notempty and {Indicate your symptoms} notempty"}]}

function sendDataToServer(survey) {
    //send Ajax request to your web server.
    alert("The results are:" + JSON.stringify(survey.data));
}

ReactDOM.render(
    <Survey.Survey json={ surveyJSON } onComplete={ sendDataToServer } />, document.getElementById("surveyContainer"));
reportWebVitals();
